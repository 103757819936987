import { Flex, Heading, Icon, Text } from "@chakra-ui/react"
import { BsFillArrowUpRightSquareFill } from 'react-icons/bs'
import { useNavigate } from "react-router-dom"
import { caseStudyData } from "../../data/caseStudies"
import CaseStudy from "./CaseStudy"

const PreviewCaseStudy = () => {
    const navigate = useNavigate()

    return (
        <>
            <Text
                textAlign="center"
                fontSize="38px"
                color="accent.300"
                fontWeight={700}
            >
                Case Studies
            </Text>
            <Flex flexWrap="wrap">
                {caseStudyData.map((caseStudy) => (
                    <CaseStudy
                        title={caseStudy.title}
                        subtitle={caseStudy.subtitle}
                        content={caseStudy.content}
                        key={caseStudy.title}
                    />
                ))}
                <Flex
                    boxShadow="lg"
                    mx={4}
                    flexDir="column"
                    justifyContent="center"
                    h="auto"
                    borderRadius={8}
                    mb={8}
                    p={6}
                    alignItems="center"
                    role="link"
                    onClick={() => navigate("case-studies")}
                    cursor="pointer"
                >
                    <Heading size='md'>View All Case Studies</Heading>
                    <Icon as={BsFillArrowUpRightSquareFill} boxSize={8} color="primary.500" mt={2} />
                </Flex>
            </Flex>
        </>
    )
}
export default PreviewCaseStudy
