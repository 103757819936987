import {
  Heading,
  Text,
  Flex,
  Stack,
  Image
} from '@chakra-ui/react';

const Service = ({ image, title, description }) => (
  <Flex
    boxShadow="lg"
    flex="1"
    mx={4}
    flexDir="column"
    justifyContent="space-between"
    h="auto"
    borderRadius={8}
    mb={[8, 0]}
  >
    <Image
      loading="lazy"
      src={image}
      alt='service'
      borderRadius='lg'
      w="100%"
      h="100%"
    />
    <Stack mt='6' spacing='3' p={6}>
      <Heading size='md'>{title}</Heading>
      <Text color="gray.400">{description}</Text>
    </Stack>
  </Flex>
)

export default Service
