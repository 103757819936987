import React from 'react';
import {
    Box,
    Flex,
    Button,
    Image,
    Text
} from '@chakra-ui/react';
import HeroImage from '../../images/hero-image.png';
import HeroTitle from '../../images/title.svg';
import Arrow from '../../images/arrow.svg';

const Hero = () => (
    <Flex h="93vh" alignItems="center" justifyContent="space-between" px={4}>
        <Box my="10rem" w="50%">
            <Box ml={2}>
                <Text color="accent.200" fontWeight={600}>Do More with AI</Text>
            </Box>
            <Image loading="lazy" src={HeroTitle} alt="title" />
            <Text textAlign="justify" pb={5} maxW={["100%", "90%"]}>
                Imagine a world where your business operates at peak efficiency, where complex tasks are seamlessly automated, and where insights derived
                from data unlock unparalleled opportunities. With our AI Automation Services, this vision becomes your reality.

                Whether you're a startup aiming to scale rapidly or an established enterprise seeking to redefine your industry, we are your strategic
                partners on the journey towards AI-driven success.
            </Text>
            <Flex justifyContent="space-between" maxW={["100%", "90%"]}>
                <Button
                    bg="primary.500"
                    color="white"
                    rightIcon={
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_57_436)">
                                <path d="M10.04 3.52203L13.8113 7.29336C13.9986 7.48086 14.1038 7.73503 14.1038 8.00003C14.1038 8.26503 13.9986 8.51919 13.8113 8.70669L10.0407 12.4787C9.85307 12.6663 9.59864 12.7717 9.33333 12.7717C9.06803 12.7717 8.8136 12.6663 8.626 12.4787C8.4384 12.2911 8.33301 12.0367 8.33301 11.7714C8.33301 11.5061 8.4384 11.2516 8.626 11.064L10.69 9.00003H3C2.73478 9.00003 2.48043 8.89467 2.29289 8.70713C2.10536 8.5196 2 8.26524 2 8.00003C2 7.73481 2.10536 7.48046 2.29289 7.29292C2.48043 7.10538 2.73478 7.00003 3 7.00003H10.69L8.62667 4.93603C8.43916 4.74843 8.33385 4.49403 8.33392 4.22879C8.33398 3.96355 8.4394 3.7092 8.627 3.52169C8.8146 3.33419 9.069 3.22888 9.33424 3.22894C9.59947 3.22901 9.85383 3.33443 10.0413 3.52203H10.04Z" fill="white" />
                            </g>
                            <defs>
                                <clipPath id="clip0_57_436">
                                    <rect width="16" height="16" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    }
                    className="hs-cta-trigger-button hs-cta-trigger-button-77370423249"
                >
                    Get Started
                </Button>
                <Box
                    display="inline-block"
                    transform={["scaleX(-1) rotate(90deg)", "none"]}
                >
                    <Image loading="lazy" src={Arrow} alt="hero" />
                </Box>
            </Flex>
        </Box>
        <Image loading="lazy" display={["none", "block"]} src={HeroImage} alt="hero" />
    </Flex>
);

export default Hero;
