import React from 'react';
import {
    Box,
    Flex,
    Heading,
    Text
} from '@chakra-ui/react';
import Service from './Service';
import ButtonWithDot from '../ButtonWithDot';
import ServiceImage1 from "../../images/services/Service1.svg"
import ServiceImage2 from "../../images/services/Service2.svg"
import ServiceImage3 from "../../images/services/Service3.svg"
import ServiceImage4 from "../../images/services/Service4.svg"

const Services = () => {
    return (
        <Flex alignItems="center" justifyContent="center" flexDir="column" my={5}>
            <Box w={["96%", "60%"]} textAlign="center">
                <ButtonWithDot>
                    OUR SERVICES
                </ButtonWithDot>
                <Heading as="h3" my={5} color="gray.600">Unlocking AI excellence</Heading>
                <Text color="gray.600">
                    Shoman is a cutting-edge technology company that specializes in providing AI-powered automation solutions to
                    businesses across various industries. Shoman aims to revolutionize the way businesses operate by leveraging the power of
                    artificial intelligence and machine learning to streamline processes, enhance productivity, and drive innovation.
                </Text>
            </Box>
            <Flex my={5} flexDir={["column", "row"]} w="100%">
                <Service
                    title="AI Consultancy"
                    description="We conduct an in-depth assessments of our clients' business processes to identify automation opportunities and formulate an AI strategy aligned with their goals"
                    image={ServiceImage1}
                />
                <Service
                    title="AI Development"
                    description="Our team of experienced AI developers will design and build custom AI solutions tailored to each client's unique requirements"
                    image={ServiceImage2}
                />
                <Service
                    title="AI Implementation"
                    description="We oversee the smooth integration of AI automation solutions into the existing systems, ensuring a seamless transition and minimal disruption to day-to-day operations"
                    image={ServiceImage3}
                />
                <Service
                    title="Support & Maintenance"
                    description="We offer ongoing support and maintenance services to ensure the optimal performance and reliability of the implemented AI solutions, to ensure you meeting your objectives"
                    image={ServiceImage4}
                />
            </Flex>
        </Flex>
    );
}

export default Services;
