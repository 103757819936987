import React, { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom'
import { Routes, Route } from 'react-router-dom'
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme';
import { TermsOfUse } from './components';
import { About, Blog, LandingPage, ViewArticle, CaseStudies, ViewCaseStudy } from './pages';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
  <StrictMode>
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          <Route exact path="terms-of-use" element={<TermsOfUse />} />
          <Route exact path="blog" element={<Blog />} />
          <Route exact path="about" element={<About />} />
          <Route exact path="blog/:tag/:name" element={<ViewArticle />} />
          <Route exact path="case-studies" element={<CaseStudies />} />
          <Route exact path="case-studies/:name" element={<ViewCaseStudy />} />
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  </StrictMode>
);

