import React from 'react';
import {
    Box,
    Flex,
    Heading,
    Image,
    Divider
} from '@chakra-ui/react';
import { PiStrategyBold } from 'react-icons/pi'
import { TbAdjustmentsSearch } from 'react-icons/tb'
import { MdWorkHistory } from 'react-icons/md'
import { FaPencilRuler } from 'react-icons/fa'
import {
    ButtonWithDot,
    UniqueApproachItem,
    Contact,
    Nav,
    Footer,
    Services,
    Hero
} from '../../components';
import UniqueApproachImage from '../../images/approach.svg'
import PreviewCaseStudy from '../../components/CaseStudies/PreviewCaseStudy';


const LandingPage = () => (
    <>
        <Box minH="100vh" bg="#FFF" textAlign="left" p={["2px 10px", "15px 100px"]}>
            <Flex minH="100vh" flexDir="column">
                {/* nav */}
                <Nav />

                {/* hero */}
                <Hero />
            </Flex>

            <Box>
                {/* services */}
                <Services />

                {/* unique approach(The How?) */}
                <Flex my={["0.5rem", 10]} alignItems="center" justifyContent="center" w="100%">
                    <Image
                        loading="lazy"
                        display={["none", "block"]}
                        src={UniqueApproachImage}
                        alt='unique approach'
                    />
                    <Box px={["5px", "40px"]}>
                        <ButtonWithDot>
                            HOW IT WORKS
                        </ButtonWithDot>
                        <Heading as="h5" my={3} color="gray.600">Our Uniqued Approach</Heading>
                        <Flex flexDir="column">
                            <UniqueApproachItem
                                icon={PiStrategyBold}
                                title="Analyse"
                                description="We sit down together, analyse and study your business to come up with unique patterns to current problems"
                            />
                            <UniqueApproachItem
                                icon={TbAdjustmentsSearch}
                                title="Research"
                                description="We conduct an indepth research as per the business requirements and client needs leveraging AI"
                            />
                            <UniqueApproachItem
                                icon={MdWorkHistory}
                                title="Implement"
                                description="Our team get their hands dirty to come up with efficient solutions that gathers business needs"
                            />
                            <UniqueApproachItem
                                icon={FaPencilRuler}
                                title="Measure"
                                description="Our customer success team will work hand in hand with you to determine your goals were met and streamline your business for success as per your needs"
                            />
                        </Flex>
                    </Box>
                </Flex>

                {/* Case Studies */}
                <PreviewCaseStudy />

                {/* contacts */}
                <Contact />
            </Box>
        </Box>
        <Divider borderColor="accent.200" />
        <Footer />
    </>
);

export default LandingPage;
