export const caseStudyData = [
    {
        title: "Revolutionizing E-Commerce with Next-Level Chatbot Automation",
        subtitle: "Did you know you can do all your shopping from a chatbot?",
        content: `
        <div>
            In a world brimming with online retailers, Naivas dared to dream differently. The story of how a retail giant transformed its 
            e-commerce landscape unveils a saga of innovation, engagement, and personalized shopping experiences. Amidst the digital bustle, 
            Naivas embarked on a journey with Shoman, an AI Automation Agency renowned for its visionary prowess. Together, they sculpted a 
            groundbreaking symphony of technology and retail, giving birth to a multi-functional chatbot ecosystem. Brace yourself for a tale 
            where customer queries meet swift responses, where product interactions transcend imagination, and where seamless purchases come 
            to life – all within the confines of a chatbot interface. 
            <br />
            <b>Challenge</b><br />
            Naivas, an online retail business, sought to differentiate itself in a crowded e-commerce landscape by delivering a truly interactive and personalized shopping experience. 
            The company envisioned leveraging AI automation to enhance customer engagement, streamline purchases, and redefine online shopping. 
            <br />
            <b>Solution</b><br />
             Naivas partnered with Shoman, an AI Automation Agency renowned for its innovative solutions. Together, they embarked on a 
            groundbreaking project to create a multi-functional chatbot ecosystem that would not only answer customer queries but also showcase products, 
            facilitate product interactions, and enable seamless purchasing – all within the chatbot interface.
            <br />
            <b>Implementation</b><br />
            <ul>
                <li>
                    <b>FAQ Chatbot:</b> Shoman developed a chatbot capable of promptly addressing customer queries by intelligently understanding and responding to frequently 
                    asked questions. This chatbot offered instant assistance, eliminating the need for customers to browse through extensive FAQ pages.
                </li>
                <li>
                    <b>Product Showcase Chatbot:</b> A second chatbot was designed to showcase a variety of products. This chatbot used AI to analyze user preferences,
                    browsing history, and trends to present personalized product recommendations, enhancing user engagement.
                </li>
                <li>
                    <b>Interactive Product Interaction:</b> Shoman created a unique chatbot that allowed users to interact with products by asking questions and 
                    receiving detailed answers. Users could inquire about product specifications, features, materials, and more. The chatbot also recommended 
                    complementary products and provided real-time pricing.
                </li>
                <li>
                    <b>Seamless Purchase Chatbot:</b> The final component of the ecosystem was an AI-driven chatbot that mimicked the entire e-commerce website experience.
                    Users could browse, add items to their cart, inquire about shipping options, and complete purchases – all within the chat interface.
                </li>
            </ul>
            <br />
            <b>Results</b><br />
            <ul>
                <li>
                    <b>Enhanced User Experience:</b> Naivas provided an innovative and personalized shopping experience that went beyond traditional websites.
                </li>
                <li>
                    <b>Increased Engagement:</b> Users spent more time interacting with the chatbots, leading to improved user engagement and retention.
                </li>
                <li>
                    <b>Higher Conversion Rates:</b> The interactive chatbots facilitated smoother purchasing journeys, resulting in higher conversion rates and sales.
                </li>
                <li>
                    <b>Customer Insights:</b> The chatbots collected data on user preferences and behaviors, enabling Naivas to refine its product offerings and 
                    marketing strategies.
                </li>
                <li>
                    <b>Brand Differentiation:</b> Naivas stood out in the market by offering a truly unique and convenient shopping experience through 
                    AI-powered chatbots.
                </li>
                <li>
                    <b>Personalized Shopping Experience:</b> Users received tailored product recommendations and information based on their preferences, 
                    leading to higher customer satisfaction.
                </li>
                <li>
                    <b>Seamless Purchasing:</b> The interactive product chatbot streamlined the purchasing process, allowing users to explore, inquire, and complete transactions 
                    without leaving the chatbot interface.
                </li>
                <li>
                    <b>24/7 Availability:</b> The AI chatbots provided round-the-clock support and shopping assistance, catering to users in different time zones.
                </li>
            </ul>
            <br />
            <b>Conclusion</b> 
            Through its collaboration with Shoman, Naivas redefined online shopping by introducing a revolutionary chatbot ecosystem. This case study demonstrates 
            how Shoman leverages AI automation to create innovative solutions that enhance customer experiences, drive engagement, and elevate businesses in 
            the e-commerce sector.
        </div>
    `,
        tags: ["Case Study", "Automation"]
    },
    {
        title: "Transforming Customer Support with AI Automation",
        subtitle: "Automate customer inquiries, knowledge base and support with AI Automation",
        content: `
    In the dynamic landscape of customer support, a silent revolution is underway. The echoes of this transformation resound from Nairobi to Mombasa, 
    as KenSupport Solutions propels the realm of assistance into the future. Enter a realm where inquiries are met with instant answers, 
    where businesses gain newfound efficiencies, and where government agencies bridge the communication gap with citizens. This is the saga of 
    "Transforming Customer Support with AI Automation in Kenya." In a world demanding quicker, smarter solutions, KenSupport Solutions stepped 
    forth as the heralds of change, armed with AI-powered ingenuity. Join us on a journey that unravels how this AI Automation Agency reshaped 
    support paradigms for SMEs, corporates, startups, and government bodies, transcending traditional boundaries and propelling Kenya into a 
    new era of interaction.
    <b>Challenge</b><br />
    KenSupport Solutions, an AI Automation Agency, recognized the growing need for efficient and cost-effective customer support solutions 
    across various sectors in Kenya, including SMEs, corporates, startups, and government agencies. Traditional customer support methods 
    were often time-consuming, lacked scalability, and resulted in delayed responses, impacting customer satisfaction and organizational efficiency.
    <br />
    <b>Solution</b><br />
    KenSupport Solutions partnered with businesses across the SME, corporate, startup, and government segments to implement AI-powered customer support 
    automation solutions. The agency developed tailored AI chatbots and virtual assistants capable of handling a range of customer inquiries, 
    streamlining communication, and enhancing the overall customer experience.
    <br />
    <b>Implementation</b><br />
    <ul>
        <li>
        <b>SME Segment:</b> For small and medium-sized enterprises, KenSupport Solutions deployed AI chatbots on clients' websites and social media platforms. 
        These chatbots provided instant responses to frequently asked questions, freeing up staff to focus on more complex customer interactions.
        </li>
        <li>
        <b>Corporate Segment:</b> In collaboration with corporate clients, KenSupport Solutions integrated AI virtual assistants into their internal helpdesk systems. 
        These virtual assistants automated employee support, handling HR-related inquiries, IT troubleshooting, and general information requests.
        </li>
        <li>
        <b>Startup Segment:</b> KenSupport Solutions designed AI chatbots specifically for startups, enabling them to provide round-the-clock customer 
    support without the need for extensive staffing. The chatbots assisted with product inquiries, onboarding guidance, and billing-related questions.
        </li>
        <li>
    <b>Government Segment:</b> Partnering with government agencies, KenSupport Solutions developed AI-driven chatbots for public service portals. 
    These chatbots offered citizens information about government services, regulations, and processes, improving accessibility and reducing query backlogs.
    </li>
    </ul>
    <br />
    <b>Results</b><br />
    <ul>
        <li>
            <b>Enhanced Customer Experience:</b> Across all segments, customers received instant responses and support, leading to higher satisfaction levels 
            and improved brand perception.
        </li>
        <li>
            <b>Efficiency Gains:</b> Businesses experienced reduced response times, allowing staff to focus on more complex tasks and strategic initiatives.
        </li>
        <li>
            <b>Cost Savings:</b> AI-powered customer support significantly lowered operational costs compared to traditional call centers or manual support teams.
        </li>
        <li>
            <b>Scalability:</b> The automated solutions easily scaled to handle increased customer inquiries during peak periods.
        </li>
        <li>
            <b>Improved Government-Citizen Communication:</b> Citizens gained access to accurate and timely information from government portals, 
            reducing the need for direct inquiries and enhancing transparency.
        </li>
    </ul>
    <br />
    <b>Conclusion</b>
    Through its AI-powered customer support automation solutions, KenSupport Solutions revolutionized customer interactions across various sectors in Kenya. 
    The agency successfully addressed the diverse needs of SMEs, corporates, startups, and government agencies by providing efficient, cost-effective, and 
    scalable customer support. This case study serves as a testament to KenSupport Solutions' commitment to leveraging AI technology for positive, real-world impact.
    `,
        tags: ["Case Study", "Automation"]
    }
]