import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  fonts: {
    body: "'Inter', sans-serif",
    heading: "'Inter', sans-serif",
    mono: "'Inter', sans-serif"
  },
  colors: {
    primary: {
      25: "#e5f1ff",
      50: "#b3d4ff",
      100: "#80b7ff",
      200: "#4d9aff",
      300: "#1a7dff",
      400: "#0063e6",
      500: "#004db3",
      600: "#003780",
      700: "#00214d",
      800: "#000b1a"
    },
    secondary: {
      25: "#eafafa",
      50: "#c1f0f0",
      100: "#98e6e6",
      200: "#6fdcdc",
      300: "#46d2d2",
      400: "#2db9b9",
      500: "#239090",
      600: "#196767",
      700: "#0f3e3e",
      800: "#051515"
    },
    accent: {
      25: "#fff9e5",
      50: "#ffedb3",
      100: "#ffe180",
      200: "#ffd54d",
      300: "#ffc91a",
      400: "#e6b000",
      500: "#b38900",
      600: "#806200",
      700: "#4d3b00",
      800: "#1a1400"
    },
    gray: {
      50: "#F1F2F3",
      100: "#D8DBDF",
      200: "#BFC4CA",
      300: "#A6ACB5",
      400: "#8D95A0",
      500: "#747E8B",
      600: "#5D656F",
      700: "#464C53",
      800: "#2E3238",
      900: "#17191C"
    }
  }
});

export default theme;
