import React from "react";
import { Box, Text, Flex } from "@chakra-ui/react";
import { Footer, Nav } from "../../components";
import Article from "./Article";
import { blog } from "../../data/blog";

const Blog = () => {
    return (
        <Box>
            <Box minH="90vh" bg="#FFF" textAlign="left" p={["2px 10px", "15px 100px"]} mb="3rem">
                <Nav />

                <Box my={4}>
                    <Text as="h3" fontSize="20px" fontWeight={500}>Blog</Text>
                    <Text fontSize="sm" color="accent.400">Shoman articles about AI Automation</Text>
                </Box>

                {/* <Box textAlign="center" my={10}>
                    <Text
                        fontSize={["16px", "20px"]}
                        fontWeight={400}
                        color="gray.300"
                    >
                        Subscribe
                    </Text>
                </Box> */}

                {/* blog starts here */}
                <Flex flexWrap="wrap">
                    {blog.map((article) => (
                        <Article
                            title={article.title}
                            subtitle={article.subtitle}
                            content={article.content}
                            tags={article.tags}
                            type="bl"
                            path={`blog/${article.title}`}
                        />
                    ))}
                </Flex>
            </Box>

            {/* footer */}
            <Footer />
        </Box>
    )
}

export default Blog
