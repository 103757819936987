import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { Footer, Nav } from "../../components";
import Article from "../Blog/Article";
import { caseStudyData } from "../../data/caseStudies";

const CaseStudies = () => (
    <Box>
        <Box minH="90vh" bg="#FFF" textAlign="left" p={["2px 10px", "15px 100px"]} mb="3rem">
            <Nav />

            <Box my={6}>
                <Text as="h3" fontSize="20px" fontWeight={500}>AI Automation Case Studies</Text>
                <Text fontSize="sm" color="accent.400">Proven and tested real world AI Automation Case Studies</Text>
            </Box>

            <Flex flexWrap="wrap">
                {caseStudyData.map((caseStudy) => (
                    <Article
                        title={caseStudy.title}
                        subtitle={caseStudy.subtitle}
                        content={caseStudy.content}
                        tags={caseStudy.tags}
                        type="cs"
                        path={`case-studies/${caseStudy.title}`}
                    />
                ))}
            </Flex>
        </Box>

        {/* footer */}
        <Footer />
    </Box>
)

export default CaseStudies
