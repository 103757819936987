import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { AboutUs, Footer, Nav } from "../../components";

const About = () => (
    <Box>
        <Box minH="100vh" bg="#FFF" textAlign="left" p={["2px 10px", "15px 100px"]} mb="3rem">
            <Nav />
            <Box textAlign="center" my={10}>
                <Text
                    fontSize={["22px", "40px"]}
                    fontWeight={800}
                    color="accent.400"
                >
                    We are an AI automation agency
                </Text>
                <Text
                    fontSize={["16px", "20px"]}
                    fontWeight={400}
                    color="gray.300"
                >
                    We Help Businesses identify unique patterns that already or should leverage AI
                </Text>
            </Box>
            <AboutUs />
        </Box>

        {/* footer */}
        <Footer />
    </Box>
)

export default About
