import { Button, Icon } from '@chakra-ui/react'

const ButtonWithDot = ({ children }) => (
    <Button
        bg="white"
        color="gray.600"
        border="1px solid"
        borderColor="gray.200"
        w="min-content"
        cursor="default"
        _hover={{
            bg: 'white'
        }}
    >
        <Icon viewBox='0 0 200 200' color='accent.400' mr={1}>
            <path
                fill='currentColor'
                d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'
            />
        </Icon>
        {children}
    </Button>
)

export default ButtonWithDot
