import { Flex, Heading, Divider, Icon, Box, Text, Image } from '@chakra-ui/react'
import { MdEmail, MdPhone } from 'react-icons/md'
import ContactUs from "../../images/contactus.svg"

const ContactIcon = ({ icon }) => (
    <Flex bg="accent.400" p={2} borderRadius="50%" mr={3} alignItems="center">
        <Icon as={icon} color="white" boxSize={6} />
    </Flex>
)

const Contact = () => (
    <Flex bg="secondary.100" my="3rem" py={10} borderRadius="lg" alignItems="center" justifyContent="center">
        <Flex flexDir="column" pr={[0, 10]} justifyContent="left">
            <Heading as="h3" fontSize="32px">Let's Discuss</Heading>
            <Heading as="h1" fontSize="24px" color="primary.600" fontWeight={800} mb={4}>Your AI Automation</Heading>

            <Divider borderColor="black" />

            {/* email */}
            <Flex justifyContent="start" my={4} alignItems="start">
                <ContactIcon icon={MdEmail} />
                <Box>
                    <Text color="gray.600">Email</Text>
                    <Text fontSize="22px" fontWeight={600}>contact@shoman.tech</Text>
                </Box>
            </Flex>

            <Divider borderColor="black" />

            {/* location */}
            <Flex my={4} alignItems="start">
                <ContactIcon icon={MdPhone} />
                <Box>
                    <Text color="gray.600">Phone</Text>
                    <Text fontSize="18px" fontWeight={500}>(+254) 11 466 0017</Text>
                </Box>
            </Flex>

            <Divider borderColor="black" />
        </Flex>
        <Image
            loading="lazy"
            display={["none", "block"]}
            src={ContactUs}
            alt='contact-us'
            h="100%"
        />
    </Flex>
)

export default Contact
