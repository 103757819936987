import { Flex, Icon, Box, Text } from '@chakra-ui/react'

const UniqueApproachItem = ({ icon, title, description }) => (
    <Flex alignItems="start" w={["fit-content", "500px"]}>
        <Icon as={icon} boxSize={7} mt={4} color="accent.400" />
        <Box px={3}>
            <Text my={3} color="gray.800" fontWeight={700} fontSize="18px">{title}</Text>
            <Text>{description}</Text>
        </Box>
    </Flex>

)

export default UniqueApproachItem
