import { Box, Text, Flex, Button } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { Footer, Nav } from "../../components";
import { blog } from "../../data/blog";
import { caseStudyData } from "../../data/caseStudies";

const ViewArticle = () => {
    const navigate = useNavigate()
    const { name, tag } = useParams()

    const activeArticle = tag === 'cs' ?
        caseStudyData.filter((addressItem) => addressItem.title === name) :
        blog.filter((addressItem) => addressItem.title === name)

    return (
        <Box>
            <Box minH="90vh" bg="#FFF" textAlign="left" p={["2px 10px", "15px 100px"]} mb="3rem">
                <Nav />
                <Box my={4} px={[4, "16rem"]}>
                    {activeArticle.length > 0 ? activeArticle?.map((article) => (
                        <>
                            <Text fontSize="lg" fontWeight={800}>{article.title}</Text>
                            <Text fontSize="sm" fontWeight={400} color="gray.400">
                                {article.subtitle}
                            </Text>
                            <Flex my={3}>
                                {article.tags?.map((tag) => (
                                    <Box
                                        p="2px 10px"
                                        bg="gray.50"
                                        fontSize="sm"
                                        color="gray.400"
                                        borderRadius={6}
                                        key={tag}
                                        mr={2}
                                    >
                                        {tag}
                                    </Box>
                                ))}
                            </Flex>
                            <Text textAlign="justify" my={5} dangerouslySetInnerHTML={{ __html: article.content }} />
                        </>
                    )) : (
                        <Flex flexDir="column" minH="80vh" justifyContent="center" alignItems="center">
                            <Text>Oopps!! This article can't be found.</Text>
                            <Button mt={2} bg="primary.300" color="white" onClick={() => navigate(-1)}>Go Back</Button>
                        </Flex>
                    )}
                </Box>
            </Box>

            {/* footer */}
            <Footer />
        </Box>
    )
}

export default ViewArticle 
