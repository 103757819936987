import { Box, Flex, Heading, Text } from "@chakra-ui/react"

const CaseStudy = ({ title, subtitle, content }) => (
    <Flex
        boxShadow="lg"
        flex="1"
        mx={4}
        flexDir="column"
        justifyContent="space-between"
        h="auto"
        borderRadius={8}
        mb={8}
        p={6}
    >
        <Heading size='md'>{title}</Heading>
        <Text color="gray.300">{subtitle}</Text>
        <Box
            color="gray.600"
            dangerouslySetInnerHTML={{ __html: content.substring(0, 400) }}
            pt={4}
        />
    </Flex>
)

export default CaseStudy
