import { Flex, Link, Button } from "@chakra-ui/react"
import Logo from "../../Logo"

const Nav = () => (
    <Flex alignItems="center" justifyContent="space-between" mt={4} px={3}>
        <Link href="/">
            <Logo />
        </Link>
        <Flex alignItems="center">
            <Link href="/case-studies">Case Studies</Link>
            <Link href="/blog" px={[1.5, 6]}>Blog</Link>
            <Link href="/about" px={[1.5, 6]}>About</Link>
            <Link href="https://calendly.com/shoman_tech/30min" target="_blank">
                <Button bg="primary.400" color="white">Book a Call</Button>
            </Link>
        </Flex>
    </Flex>
)
export default Nav
