import React from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  List,
  ListIcon,
  ListItem,
  Image,
  Center
} from '@chakra-ui/react';
import { CgBot } from 'react-icons/cg'
import ButtonWithDot from '../ButtonWithDot';
import UniqueApproachImage from '../../images/approach.svg'

function AboutUs() {
  return (
    <Box>
      <Box mt={["1rem", "3rem"]}>
        <Flex flexDir={["column", "row"]} alignItems="center">
          <Image
            loading="lazy"
            display={["none", "block"]}
            src={UniqueApproachImage}
            alt='unique approach'
          />
          <Box w={["100%", "50%"]} ml={[0, "5rem"]} px={[2, 0]}>
            <Heading as="h4" my={5} color="gray.600">ABOUT US</Heading>
            <Text>
              We believe in the boundless potential of AI to transform businesses and industries.
              Shoman unlocks infinite possibilities, transforming businesses, and
              empowering businesses worldwide through innovative solutions and client-centric excellence
            </Text>
            <Text fontWeight={600} mt={["1rem", "2rem"]}>Core Values</Text>
            <List spacing={3}>
              <ListItem>
                <ListIcon as={CgBot} color='green.500' />
                Integrity
              </ListItem>
              <ListItem>
                <ListIcon as={CgBot} color='green.500' />
                Reliability
              </ListItem>
              <ListItem>
                <ListIcon as={CgBot} color='green.500' />
                Adaptability
              </ListItem>
              <ListItem>
                <ListIcon as={CgBot} color='green.500' />
                Client-Centric
              </ListItem>
            </List>
            <Text fontSize="18px" fontWeight={900} my={5} color="primary.600" alignItems="end">
              Embrace the Future with AI: Innovate. Automate. Excel
            </Text>
          </Box>
        </Flex>
      </Box>

      <Flex mt={["2.5rem", "4rem"]} w="100%">
        <Box w={["100vw", "50%"]} mr={[0, "6rem"]} px={[2, 0]}>
          <ButtonWithDot>
            OUR STORY
          </ButtonWithDot>
          <Text textAlign="justify" mt="1rem">
            At Shoman, we are pioneers in the realm of AI-driven automation, poised at the forefront of technological evolution.
            Our journey is fueled by a profound belief in the boundless potential of artificial intelligence to reshape industries and ignite unprecedented growth.
            <br />
            <br />
            More than mere tech enthusiasts, we are your strategic partners on this transformative voyage. By delving deep into your business landscape,
            understanding its intricacies, challenges, and aspirations, we unlock the latent potential for AI automation.
            <br />
            <br />
            Our team of brilliant minds and
            seasoned experts spans diverse domains, ensuring bespoke solutions tailored to a spectrum of industries.
            <br />
            <br />
            What truly differentiates us is our unwavering commitment to your triumph. Beyond delivering a product, we equip you with the knowledge,
            tools, and unwavering support necessary for triumph in a swiftly evolving landscape.
            {/* <br />
            <br />
            <b>Welcome to a world of limitless possibilities.</b> */}
          </Text>
        </Box>
        <Box w="50%" display={["none", "block"]}>
          <Center>
            <Image
              loading="lazy"
              src={UniqueApproachImage}
              alt='unique approach'
            />
          </Center>
        </Box>
      </Flex>
    </Box>
  );
}

export default AboutUs;
