import { Box, Flex, Text, Link } from "@chakra-ui/react"
import Logo from "../../Logo"

const Footer = () => (
    <Box bg="secondary.100">
        <Flex justifyContent="space-between" w={["94%", "100%"]} alignItems="center">
            <Flex
                flexDir={["column", "row"]}
                justifyContent="space-between"
                p={["10px", "15px 100px"]}
                ml="1.5rem"
                w={["80%", "100%"]}
            >
                <Logo />
                <Text my={[2, 0]}>@{new Date().getFullYear()}; All Rights Reserved</Text>
                <Link href="terms-of-use" color="primary.400" textDecoration="none">Terms of Use</Link>
            </Flex>
            <Box display={["block", "none"]}>
                <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.7778 5.01247L0 50H11.3333C17.5556 42.9305 23.1111 40.6812 24.4444 39.7171C25.5111 38.9459 28.8889 38.3247 30.4444 38.1104C32.4 37.5963 33.3333 38.9674 33.5556 39.7171C34.1481 41.2167 35.6444 44.7943 36.8889 47.1079C38.1333 49.4216 39.7778 50 40.4444 50H50L40.8889 25.3736L36.4444 13.8054L34 8.34264L31.3333 3.72712C26 -2.5829 20.2222 -0.0121797 17.7778 5.01247Z" fill="#4C1F88" />
                    <path d="M17.5557 12.7242L9.33348 33.728C9.11125 34.0493 15.0372 29.8623 17.3335 28.7912L21.7779 18.3037C22.2224 17.3397 22.6668 16.1518 22.889 15.6162C23.2859 15.2949 23.3335 15.0903 24.0001 15.0903C24.6668 15.0903 24.889 14.769 24.6668 14.1263C24.4446 13.4836 23.7779 12.8409 23.7779 12.8409L22.2224 11.7602C20.0001 10.9129 18.0742 11.8673 17.5557 12.7242Z" fill="white" />
                    <path d="M23.9999 19.1524L21.5555 25.9006C21.911 25.6435 25.111 25.1508 26.6666 24.9365C27.3777 25.1936 27.7036 24.1868 27.7777 23.6512L25.9999 19.1524C25.111 17.61 24.2962 18.5098 23.9999 19.1524Z" fill="white" />
                    <path d="M22.8889 4.36991C22 4.53357 21.3333 5.12933 20.4444 6.09335L19.5555 7.70005C21.5111 7.70005 24.2963 9.51133 25.3333 10.4753C26.7555 11.5036 29.6296 20.3298 30.8889 24.6143H37.5555L31.5555 9.94943C31.0222 8.407 29.1111 5.40109 27.5555 4.808C25.3333 3.96073 24 4.16533 22.8889 4.36991Z" fill="white" />
                    <path d="M19.3332 31.0394C10.6221 35.6667 5.92576 43.0361 4.6665 46.1424H10.8887C12.8443 43.0575 19.8517 38.6445 23.1109 36.8236C26.1332 34.5099 36.6665 34.8955 36.6665 34.8955C36.6665 34.8955 40.2221 35.1097 41.5554 35.5382C41.5554 35.5382 40.7261 30.8941 39.111 29.4327C38.7819 29.1351 38.2221 28.7901 38.2221 28.7901C30.2221 26.9906 22.2961 29.5399 19.3332 31.0394Z" fill="white" />
                    <path d="M40.889 39.7183C43.5556 41.7631 45.1112 44.6552 45.7779 46.1451C46.4445 47.6351 39.1112 46.1451 39.1112 46.1451C37.8094 43.1333 36.2223 40.2442 35.7779 38.433C35.3334 36.6217 38.2223 37.6735 40.889 39.7183Z" fill="white" />
                </svg>
            </Box>
        </Flex>
    </Box>
)

export default Footer 
