import { Text, Card, CardBody, Heading, CardHeader, Flex, Box } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const ArticleTags = ({ tag }) => (
    <Box p="3px 6px" fontSize="sm" bg="gray.100" mr={1} borderRadius={6}>{tag}</Box>
)

const Article = ({ title, subtitle, content, tags, path }) => {
    const navigate = useNavigate()

    return (
        <Card m={8} flex="0 0 calc(33% - 100px)" role="button" onClick={() => navigate(path)}>
            <CardHeader>
                <Heading size='md'>{title}</Heading>
                <Text pt='2' fontSize='smaller' color="gray.300">{subtitle}</Text>
            </CardHeader>

            <CardBody pt="0">
                <Text fontSize='sm' dangerouslySetInnerHTML={{ __html: content.substring(0, 200) }} />

                {/* tags */}
                {tags?.length > 0 && (
                    <Flex mt={2}>
                        {tags.map((tag) => (
                            <ArticleTags tag={tag} />
                        ))}
                    </Flex>
                )}
            </CardBody>
        </Card>
    )
}

export default Article 
